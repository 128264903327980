<template>
  <div class="wrap">
    <div>
      <el-button style="margin-bottom: 10px" @click="sliter">筛选</el-button>
      <el-dialog width="27%" title="筛选" :visible.sync="dialogFormVisible3">
        <el-form
          v-loading="loading_show"
          label-width="120px"
          v-if="
            this.$route.query.src != '雷达' &&
            this.$route.query.src != '未简雷达'
          "
        >
          <el-form-item label="姓名">
            <el-input v-model="form.name" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="性别">
            <el-select v-model="form.gender" placeholder="请选择性别">
              <el-option label="男" value="男"> </el-option>
              <el-option label="女" value="女"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="手机">
            <el-input v-model="form.phone" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="学历">
            <el-select
              v-model="form.education_background"
              placeholder="请选择学历"
            >
              <el-option
                v-for="(item, index) in edu_list"
                :key="index"
                :value="item.label"
                :label="item.label"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="期望职位">
            <el-cascader
              v-model="form.position_id"
              :options="position_options"
              placeholder="请选择期望职位"
              filterable
              @change="position_change"
            ></el-cascader>
          </el-form-item>
        </el-form>
        <el-form v-loading="loading_show" label-width="120px" v-else>
          <el-form-item label="账号">
            <el-input v-model="form.username" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="danger" @click="clear">清 空</el-button>
          <el-button @click="dialogFormVisible3 = false">取 消</el-button>
          <el-button type="primary" @click="filtersu">确 定</el-button>
        </div>
      </el-dialog>

      <el-table
        v-loading="loading"
        :data="tableData"
        style="width: 100%; min-height: 68vh"
        v-if="
          this.$route.query.src != '雷达' && this.$route.query.src != '未简雷达'
        "
        @row-click="goDetails"
      >
        <el-table-column prop="name" label="姓名"></el-table-column>
        <el-table-column prop="gender" label="性别"></el-table-column>
        <el-table-column prop="age" label="年龄"></el-table-column>
        <el-table-column
          prop="education_background"
          label="学历"
        ></el-table-column>
        <el-table-column prop="phone" label="手机号"></el-table-column>
        <el-table-column prop="position_name" label="职位">
          <template slot-scope="scope">
            {{ scope.row.position_name.join(" , ") }}
          </template>
        </el-table-column>
        <el-table-column
          prop="update_time_str"
          label="更新时间"
        ></el-table-column>
        <el-table-column
          prop="create_time_str"
          label="创建时间"
        ></el-table-column>
      </el-table>
      <el-table
        v-loading="loading"
        :data="tableData"
        style="width: 100%; min-height: 68vh"
        v-else
      >
        <el-table-column prop="company_name" label="公司名称"></el-table-column>
        <el-table-column
          prop="company_username"
          label="公司帐号"
        ></el-table-column>
        <el-table-column prop="city_name" label="公司地址"></el-table-column>
        <el-table-column
          prop="weixin_nickname"
          label="微信昵称"
        ></el-table-column>
        <el-table-column prop="name" label="姓名"></el-table-column>
        <el-table-column prop="age" label="年龄"></el-table-column>
        <el-table-column prop="edu" label="学历"> </el-table-column>

        <el-table-column prop="weixin_gender" label="性别"></el-table-column>
        <el-table-column
          prop="weixin_place"
          label="求职者微信地点"
        ></el-table-column>

        <el-table-column
          label="是否有大简历"
          align="center"
          v-if="this.$route.query.src == '未简雷达'"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.is_dajianli == 1" style="color: blue"
              >有</span
            >
            <span v-else style="color: red">没有</span>
          </template>
        </el-table-column>

        <el-table-column
          label="浏览职位"
          align="center"
          v-if="this.$route.query.src == '未简雷达'"
        >
          <template slot-scope="scope">
            <span 
              >{{scope.row.lab_position_name }}</span
            > 
          </template>
        </el-table-column>
 



        <el-table-column prop="phone" label="求职者手机号"></el-table-column>

        <el-table-column prop="create_time" label="浏览时间"></el-table-column>
      </el-table>

      <el-pagination
        background
        @current-change="handleCurrentChange"
        :total="total"
        :page-size="15"
        :current-page="current_page"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { resume_city_detail } from "@/api/hr24_api/data_board.js";

import axios from "axios";
import tabulate from "../tool/tabulate.vue";
export default {
  components: { tabulate },
  data() {
    return {
      formLabelWidth: "120px",
      loading_show: false,
      form: {},
      edu_list: [
        { label: "初中" },
        { label: "中专" },
        { label: "高中" },
        { label: "大专" },
        { label: "本科" },
        { label: "硕士" },
      ],
      pagesize: 15,
      currentPage1: 1,
      tableData: [],
      table_list: [],
      loading: true,
      dialogFormVisible3: false,
      current_page: 1,
      total: 0,
      page: 0,
      position_options: [],
    };
  },

  created() {
    this.init(0);
    axios.get("/static/indry.json").then((res) => {
      this.position_options = res;
    });
  },

  methods: {
    //查看简历详情
    goDetails(item) {
      console.log("***************");
      console.log(item);
      this.$router.push({
        path: "/resumedetails2",
        query: { id: item.resume_id },
      });
    },

    position_change(val) {
      this.form.position_id = val[val.length - 1];
    },
    init(page, obj = {}) {
      if (page == 0) {
        this.current_page = 1;
      }
      let obj1 = {
        page,
        city_name: this.$route.query.src,
        search_data: this.form,
      };
      resume_city_detail(obj1).then((res) => {
        this.loading = false;
        console.log("ssssssssssssssssssssssssssss");
        console.log(res.data);

        if (!res.code) {
          this.tableData = res.data;
          this.total = res.resume_count;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    sliter() {
      this.dialogFormVisible3 = true;
    },
    filtersu() {
      this.init(0);

      this.loading = true;
      this.dialogFormVisible3 = false;
    },

    clear() {
      this.form = {};
      this.init(0);
      this.dialogFormVisible3 = false;
    },
    handleCurrentChange(val) {
      this.current_page = val;
      this.page = val - 1;
      this.loading = true;
      this.init(val - 1);
    },
  },
};
</script>

<style scoped>
.wrap {
  padding-top: 54px;
}
.wrap > div:first-child {
  min-height: 77vh;
}

.el-pagination {
  margin-top: 10px;
  text-align: center;
}

.el-input,
.el-select {
  min-width: 30px;
  width: 80%;
}
</style>
